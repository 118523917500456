import { useState } from 'react';
import { ReactSketchCanvas } from 'react-sketch-canvas';

function App() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    phone: '',
    message: '',
    budget: 1000,
    sketchData: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  const [sketchData, setSketchData] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    return formData.name && formData.email && formData.message;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      alert('Please fill out name, email, and message.');
      return;
    }

    setIsSubmitting(true);
    try {
      const response = await fetch('https://d8s4iof362.execute-api.eu-west-1.amazonaws.com/submit-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (response.ok) {
        setSubmissionSuccess(true);
      } else {
        alert('Submission failed: ' + data.message);
      }
    } catch (error) {
      alert('An error occurred: ' + error.message);
    }
    setIsSubmitting(false);
  };


  if (submissionSuccess) {
    return (
      <div className="flex min-h-screen items-center justify-center bg-white">
        <div className="text-center">
          <h1 className="text-4xl font-medium">Thank You!</h1>
          <p className="my-8">Your message has been successfully sent. We will get back to you soon.</p>
          {/* href button to builtonearth.com */}
          <a href="https://builtonearth.com" className="rounded-0 bg-black px-10 py-2 text-white">
            Learn more about us
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className="flex min-h-screen items-center justify-start bg-white">
      <div className="flex flex-col md:flex-row w-full max-w-6xl mx-auto px-4">
        <div className="w-full md:w-1/2 pr-0 md:pr-10">
          <div className="mx-auto w-full max-w-lg">
            <h1 className="text-4xl font-medium">Contact us</h1>
            <p className="mt-3">Email us at support@builtonearth.com or message us here:</p>

            <form onSubmit={handleSubmit} className="mt-10">
              <input type="hidden" name="access_key" value="YOUR_ACCESS_KEY_HERE" />
              <div className="grid gap-6 sm:grid-cols-2">
                {/* Name */}
                <div className="relative z-0">
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="peer block w-full appearance-none border-0 border-b border-gray-500 bg-transparent py-2.5 px-0 text-sm text-gray-900 focus:border-black-600 focus:outline-none focus:ring-0"
                    placeholder=" "
                  />
                  <label className="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-black-600">Your name</label>
                </div>
                {/* Email */}
                <div className="relative z-0">
                  <input
                    type="text"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="peer block w-full appearance-none border-0 border-b border-gray-500 bg-transparent py-2.5 px-0 text-sm text-gray-900 focus:border-black-600 focus:outline-none focus:ring-0"
                    placeholder=" "
                  />
                  <label className="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-black-600">Your email</label>
                </div>
                {/* Company */}
                <div className="relative z-0 col-span-2">
                  <input
                    type="text"
                    name="company"
                    value={formData.company}
                    onChange={handleChange}
                    className="peer block w-full appearance-none border-0 border-b border-gray-500 bg-transparent py-2.5 px-0 text-sm text-gray-900 focus:border-black-600 focus:outline-none focus:ring-0"
                    placeholder=" "
                  />
                  <label className="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-black-600">Company</label>
                </div>
                {/* Phone Number */}
                <div className="relative z-0 col-span-2">
                  <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="peer block w-full appearance-none border-0 border-b border-gray-500 bg-transparent py-2.5 px-0 text-sm text-gray-900 focus:border-black-600 focus:outline-none focus:ring-0"
                    placeholder=" "
                  />
                  <label className="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-black-600">Phone number</label>
                </div>
                {/* Message */}
                <div className="relative z-0 col-span-2">
                  <textarea
                    name="message"
                    rows="5"
                    value={formData.message}
                    onChange={handleChange}
                    className="peer block w-full appearance-none border-0 border-b border-gray-500 bg-transparent py-2.5 px-0 text-sm text-gray-900 focus:border-black-600 focus:outline-none focus:ring-0"
                    placeholder=" "
                  />
                  <label className="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-black-600">Your message</label>
                </div>
                {/* Budget Slider */}
                <div className="relative z-0 col-span-2">
                  <label htmlFor="budget" className="block text-sm font-medium text-gray-700">
                    Approximate budget
                  </label>
                  <div className="flex mt-5 text-gray-700 justify-between">
                    <span>£1,000</span>
                    <span>£15,000</span>
                  </div>
                  <input
                    type="range"
                    id="budget"
                    name="budget"
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-200 outline-none"
                    min="1000"
                    max="15000"
                    step="500"
                    value={formData.budget}
                    onChange={handleChange}
                  />
                  {/* Display Current Budget Value */}
                  <div className="text-center mt-2">
                    <span className="text-md font-medium text-gray-700"> Budget: £{formData.budget}</span>
                  </div>
                </div>

              </div>
            </form>
          </div>
        </div>
        <div className="w-full md:w-1/2 mt-10 md:mt-0">

          <h1 className="text-md font-medium text-gray-700 mb-4">Send us a little scribble</h1>

          <ReactSketchCanvas
            className="sketch-canvas"
            style={{ border: '1px solid #939393' }}
            onChange={(data) => {
              setSketchData(data);
              setFormData({ ...formData, sketchData: data });
            }}
          />

          <div className="flex justify-end">
            <button type="submit" className="mt-5 rounded-0 bg-black px-10 py-2 text-white" disabled={isSubmitting} onClick={handleSubmit}>
              {isSubmitting ? 'Sending...' : 'Send Message'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
